import React, {useState} from 'react';
import {API, graphqlOperation} from "aws-amplify";
import {putGraphRagMessage} from "../../graphql/queries";
import {onGraphRagChatResponse} from "../../graphql/subscriptions";
import 'react-chat-elements/dist/main.css'
import {Box, Button, CircularProgress, TextField, Tooltip} from "@mui/material";

import BasicSnackbar from "../../components/common/BasicSnackbar/BasicSnackbar";
import {MessageBox} from "react-chat-elements";
import IconButton from "@mui/material/IconButton";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _ from 'lodash';


const drawerWidth = 240;
const inputReferance = React.createRef()


const GraphChat = () => {
    const [open, setOpen] = useState(false)
    const [campaign, setCampaign] = useState(null)
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([{text: "starting GraphRAG chat...", type: 'system'}]);
    const [loading, setLoading] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const simulateResponse = () => {
        setLoading(true); // Show spinner
        setTimeout(() => {
            // Simulate a delay for the response
            setMessages((prev) => [
                ...prev,
                {text: "this is a simulated response", type: 'system'},
            ]);
            setLoading(false); // Hide spinner
        }, 5000); // 2 seconds delay
    };

    const handleSendMessage = async () => {
        if (message.trim() == "") return
        let error_msg_tittle = "Oops! Something went wrong on our end."
        let error_msg = "Don’t worry, it’s not you—it’s me. Could you try rephrasing or asking again? I’m here to help!"
        let tmp_messages = []
        tmp_messages = _.union(messages, [])

        tmp_messages = _.union(tmp_messages, [{
            text: message,
            status: 'sent',
            type: 'text',
            direction: 'right',
            date: _.now()
        }])
        setMessages(tmp_messages);

        setMessage("");

        try {
            setLoading(true); // Show spinner
            const result = await API.graphql({
                query: putGraphRagMessage,
                variables: {"message": message},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            const apiRespnse = result.data.putGraphRagMessage;

            console.log(`Send Message response : ${apiRespnse}`)

            // Subscribe to chat assistant response
            if (apiRespnse.statusCode === "200") {

                let graphragResponseSub = API.graphql({
                    ...graphqlOperation(onGraphRagChatResponse, {id: apiRespnse.id}),
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                }).subscribe({
                    next: ({provider, value}) => {

                        let subApiRespnse = value.data.onGraphRagChatResponse;

                        let body = JSON.parse(subApiRespnse.body)
                        console.log(body)

                        if (subApiRespnse.statusCode === "200") {
                            tmp_messages = _.union(tmp_messages, [{
                                text: body.message,
                                type: 'text',
                                direction: 'left',
                                date: _.now()
                            }])
                        } else {
                             tmp_messages = _.union(tmp_messages, [{
                                text: error_msg,
                                title: error_msg_tittle,
                                focus: true,
                                type: 'text',
                                direction: 'left',
                                date: _.now()
                            }])
                        }
                        setMessages(tmp_messages);

                        if (subApiRespnse.status === 'done') {
                            graphragResponseSub.unsubscribe();
                        }
                        setLoading(false);
                    },
                    error: (error) => {
                        console.warn(error)

                        tmp_messages = _.union(tmp_messages, [{
                            text: error_msg,
                            title: error_msg_tittle,
                            type: 'text',
                            direction: 'left',
                            date: _.now()
                        }])
                        setMessages(tmp_messages);
                        setLoading(false);
                    }
                });
            } else {
                tmp_messages = _.union(tmp_messages, [{
                    text: error_msg,
                    title: error_msg_tittle,
                    type: 'text',
                    direction: 'left',
                    date: _.now()
                }])
                setMessages(tmp_messages);
                setLoading(false);
            }
        } catch (error) {
            console.log('querry failed ->', error)

            tmp_messages = _.union(tmp_messages, [{
                text: error_msg,
                title: error_msg_tittle,
                focus: true,
                type: 'text',
                direction: 'left',
                date: _.now()
            }])
            setMessages(tmp_messages);
            setLoading(false);
        }
    };
    const onSelectCampaign = (e, campaign) => {
        e.preventDefault()
        console.log(campaign)
        setCampaign(campaign)
    }

    return (
        <Box
            component="main"
            sx={{
                marginTop: '46px',
                display: "flex",
                flexGrow: 1,
                p: 3,
                width: {sm: `calc(100% - ${drawerWidth}px)`},
                flexDirection: "column",
                height: "100vh",
            }}
        >

            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    padding: 2,
                    backgroundColor: "#f9f9f9",
                }}
            >
                {messages.map((msg, index) => (
                    <Box>
                        <MessageBox
                            position={msg.direction}
                            type={msg.type}
                            text={msg.text}
                            title={msg.title}
                            status={msg.status}
                            statusTitle={msg.statusTitle}
                            focus={true}
                            date={msg.date}
                        />
                        {/* Action Icons */}
                        <Box
                            className="actions"
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                display: "flex",
                                gap: 1,
                                opacity: 0,
                                transition: "opacity 0.3s, transform 0.3s",
                                transform: "translateY(10px)",
                            }}
                        >
                            <Tooltip title="Like">
                                <IconButton size="small">
                                    <ThumbUpAltOutlinedIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Dislike">
                                <IconButton size="small">
                                    <ThumbDownAltOutlinedIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="More Actions">
                                <IconButton size="small">
                                    <MoreVertIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                ))}

                {/* Loader Spinner */}
                {loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                        mb={2}
                    >
                        <CircularProgress size={24}/>
                    </Box>
                )}
            </Box>

            {/* Chat Input (Fixed at Bottom) */
            }
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                    padding: 2,
                    borderTop: "1px solid #ccc",
                    backgroundColor: "#fff",
                    position: "sticky",
                    bottom: 0,
                }}
            >
                {/* Text Input */}
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your message..."
                    value={message}
                    disabled={loading}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") handleSendMessage();
                    }}
                />

                {/* Send Button */}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSendMessage}
                >
                    Send
                </Button>
            </Box>

            <BasicSnackbar
                open={open}
                severity="error"
                message="Data couldn't be fetched"
                onClose={handleClose}
            />
        </Box>
    )
}

export default GraphChat