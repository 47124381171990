import {createSvgIcon} from "@mui/material";
import * as React from "react";

const CompanyIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="89" height="78" viewBox="0 0 89 78" fill="none">
        <g clipPath="url(#clip0_175_4081)">
            <path
                d="M7.00827 72.5263H3.17529L2.34375 75.1642H0L3.78706 64.2856H6.47031L10.2656 75.1642H7.83818L7.00663 72.5263H7.00827ZM3.66733 70.8222H6.51788L5.11884 66.3674H5.06636L3.66733 70.8222Z"
                fill="#0d0d0d"/>
            <path d="M11.3789 75.1642V64.2856H13.662V75.1642H11.3789Z" fill="#0d0d0d"/>
            <path
                d="M24.9969 75.1642V67.8213H24.9362L21.9725 75.0579H20.5357L17.572 67.8213H17.5113V75.1642H15.4775V64.2856H18.1231L21.2229 71.9981H21.2836L24.3834 64.2856H27.029V75.1642H24.9952H24.9969Z"
                fill="#0d0d0d"/>
            <path
                d="M30.6389 72.0668C30.7455 72.9646 31.682 73.5517 32.8924 73.5517C34.1028 73.5517 34.9409 72.9712 34.9409 72.1715C34.9409 71.4633 34.4194 71.0708 33.104 70.7765L31.682 70.4674C29.6712 70.0373 28.6806 69.0201 28.6806 67.4288C28.6806 65.4238 30.4191 64.0959 32.8547 64.0959C35.2903 64.0959 36.9911 65.3993 37.0288 67.3683H34.8884C34.813 66.4492 34.0044 65.8899 32.8629 65.8899C31.7214 65.8899 30.98 66.4247 30.98 67.2326C30.98 67.9031 31.5098 68.2809 32.7645 68.567L34.0881 68.8467C36.2727 69.3144 37.2256 70.2482 37.2256 71.9065C37.2256 74.0178 35.5101 75.3523 32.8022 75.3523C30.0943 75.3523 28.5083 74.1078 28.4395 72.0652H30.6389V72.0668Z"
                fill="#0d0d0d"/>
            <path
                d="M42.6914 73.506H42.6685V75.1642H41.709V64.2856H42.7144V68.9596H42.7373C43.1835 67.8818 44.1971 67.195 45.4288 67.195C47.4019 67.195 48.7484 68.8009 48.7484 71.2426C48.7484 73.6842 47.4019 75.2902 45.4222 75.2902C44.1675 75.2902 43.1621 74.6049 42.6931 73.5043L42.6914 73.506ZM42.7078 71.2442C42.7078 73.1282 43.7509 74.4103 45.2336 74.4103C46.7163 74.4103 47.7217 73.1658 47.7217 71.2442C47.7217 69.3226 46.7458 68.0781 45.2336 68.0781C43.7214 68.0781 42.7078 69.3668 42.7078 71.2442Z"
                fill="#0d0d0d"/>
            <path
                d="M49.9846 77.9542V77.1022C50.106 77.1316 50.3028 77.148 50.4603 77.148C51.1787 77.148 51.5723 76.7865 51.92 75.8053L52.1316 75.2166L49.1826 67.3242H50.2569L52.6466 74.1699H52.6695L55.0658 67.3242H56.1171L53.0254 75.6385C52.3382 77.4848 51.7773 77.9984 50.4849 77.9984C50.3258 77.9984 50.0994 77.9755 49.9863 77.9526L49.9846 77.9542Z"
                fill="#0d0d0d"/>
            <path
                d="M66.4748 71.833H61.8939L60.6835 75.1659H59.5879L63.6702 64.2873H64.6985L68.7743 75.1659H67.6852L66.4748 71.833ZM62.2039 70.9433H66.1648L64.2065 65.5612H64.1606L62.2023 70.9433H62.2039Z"
                fill="#0d0d0d"/>
            <path
                d="M69.9766 75.1642V64.2856H70.9967L77.3932 73.3702H77.4457V64.2856H78.4659V75.1642H77.4687L71.0492 66.0731H70.9967V75.1642H69.9766Z"
                fill="#0d0d0d"/>
            <path
                d="M80.5107 75.1642V64.2856H81.5309L87.9274 73.3702H87.9799V64.2856H89.0001V75.1642H88.0029L81.5834 66.0731H81.5309V75.1642H80.5107Z"
                fill="#0d0d0d"/>
            <path
                d="M26.5323 7.99706C26.5323 3.49974 30.0208 0.0294368 34.5869 -2.82569e-07C38.8595 -0.0294373 42.3644 3.49974 42.389 7.84988C42.4136 12.2246 38.889 15.8322 34.5722 15.8617C30.1159 15.8911 26.5323 12.3865 26.5273 7.99706H26.5323Z"
                fill="#0d0d0d"/>
            <path
                d="M61.8509 8.12134C61.8411 12.411 58.2623 15.9303 53.9439 15.896C49.5221 15.8617 45.9532 12.2932 45.9482 7.90874C45.9482 3.58313 49.5221 0.00980172 53.8652 -1.06299e-05C58.2968 -0.00982298 61.8559 3.61257 61.846 8.12134H61.8509ZM54.0242 11.4477C55.8924 11.4232 57.5095 9.7273 57.4439 7.86459C57.3799 5.98062 55.7087 4.42863 53.811 4.48914C51.9118 4.54801 50.4898 6.08037 50.5193 8.01341C50.5537 10.0151 52.0364 11.4739 54.0193 11.4494H54.0242V11.4477Z"
                fill="#0d0d0d"/>
            <path
                d="M52.8529 28.7518C52.8431 33.0414 49.2643 36.5608 44.9458 36.5265C40.524 36.4921 36.9551 32.9237 36.9502 28.5392C36.9502 24.2136 40.524 20.6403 44.8671 20.6305C49.2987 20.6206 52.8578 24.243 52.848 28.7518H52.8529ZM45.0262 32.0782C46.8943 32.0537 48.5115 30.3578 48.4459 28.495C48.3819 26.6111 46.7106 25.0591 44.813 25.1196C42.9137 25.1785 41.4917 26.7108 41.5212 28.6439C41.5557 30.6456 43.0384 32.1044 45.0213 32.0798H45.0262V32.0782Z"
                fill="#0d0d0d"/>
            <path
                d="M82.8054 31.46C82.7168 35.9426 79.6826 38.973 75.3395 38.8144C71.8936 38.6901 68.0721 36.2992 68.3394 31.0054C68.533 27.1949 71.8739 23.9961 75.7709 24.1106C79.8203 24.23 82.889 27.4321 82.8103 31.46H82.8054Z"
                fill="#0d0d0d"/>
            <path
                d="M65.5032 37.6598C65.4638 39.5928 64.155 40.9028 62.2804 40.8324C60.7928 40.7785 59.1428 39.7449 59.2609 37.4619C59.3445 35.8167 60.7878 34.4364 62.4673 34.4871C64.2124 34.5362 65.5409 35.9197 65.5065 37.6598H65.5016H65.5032Z"
                fill="#0d0d0d"/>
            <path
                d="M74.1799 33.8133C75.6724 33.8182 76.9058 32.6767 76.9353 31.267C76.9648 29.8786 75.6855 28.5932 74.2832 28.5932C72.8809 28.5932 71.7197 29.7641 71.7246 31.1885C71.7295 32.7798 72.6857 33.8035 74.1733 33.8084L74.1783 33.8133H74.1799Z"
                fill="#0d0d0d"/>
            <path
                d="M6.6786 31.4404C6.53427 27.4909 9.68332 24.2283 13.7623 24.1057C17.5149 23.9912 20.9904 27.1753 21.1183 30.8484C21.2823 35.4553 18.4859 38.713 14.2675 38.8307C9.81124 38.955 6.84753 36.0669 6.6786 31.442V31.4404Z"
                fill="#0d0d0d"/>
            <path
                d="M17.0395 31.2425C17.0297 29.7739 15.8307 28.5638 14.3972 28.5785C13.0146 28.5932 11.8436 29.7151 11.819 31.0545C11.7894 32.6457 12.8457 33.8019 14.3316 33.8019C15.893 33.8019 17.0477 32.7144 17.0379 31.2409L17.0395 31.2425Z"
                fill="#0d0d0d"/>
            <path
                d="M58.3753 42.2405C61.9196 42.1522 64.9932 45.0681 65.0965 48.6218C65.1999 52.097 62.351 55.1258 58.8952 55.2255C55.0343 55.3335 51.9903 52.6449 51.9214 49.0667C51.8476 45.2366 54.5882 42.3403 58.3753 42.2405ZM61.0733 48.7805C61.0831 47.4067 59.894 46.1949 58.5147 46.1753C57.196 46.1557 56.0365 47.3217 56.0168 48.6856C55.9971 50.0643 57.0927 51.1616 58.495 51.1616C59.8973 51.1616 61.0684 50.1084 61.0733 48.7788V48.7805Z"
                fill="#0d0d0d"/>
            <path
                d="M31.1365 42.2847C34.6316 42.2602 37.6249 45.2055 37.6806 48.72C37.7348 52.254 34.8203 55.2403 31.2907 55.2795C27.5971 55.3188 24.6137 52.4912 24.5842 48.9326C24.5547 45.284 27.484 42.3141 31.1382 42.2896V42.2847H31.1365Z"
                fill="#0d0d0d"/>
            <path
                d="M25.4022 15.3727C25.3875 18.0514 23.2799 20.1169 20.5983 20.0924C17.8265 20.063 15.7648 17.9811 15.7993 15.2435C15.8288 12.5598 17.9462 10.4142 20.5474 10.4338C23.3685 10.4534 25.4203 12.5402 25.4055 15.371H25.4006L25.4022 15.3727ZM22.2532 15.2484C22.2778 14.3882 21.4807 13.5623 20.6229 13.5623C19.6814 13.5623 18.9467 14.2639 18.9319 15.1732C18.9171 16.107 19.6158 16.8347 20.5376 16.8543C21.4151 16.874 22.2236 16.1135 22.2532 15.2435V15.2484Z"
                fill="#0d0d0d"/>
            <path
                d="M73.1185 15.353C73.0988 18.0171 71.0175 20.0679 68.3047 20.0924C65.692 20.1169 63.5303 18.0171 63.5254 15.4463C63.5205 12.6334 65.7412 10.3651 68.477 10.3945C71.0142 10.424 73.1366 12.6874 73.1169 15.3514L73.1185 15.353ZM70.0203 15.2484C70.0351 14.3195 69.2921 13.523 68.4195 13.523C67.4633 13.523 66.8139 14.2099 66.8139 15.214C66.8139 16.1331 67.4535 16.8347 68.3211 16.8641C69.2232 16.8936 70.0023 16.1528 70.017 15.2484H70.022H70.0203Z"
                fill="#0d0d0d"/>
            <path
                d="M65.4006 27.0526C65.3514 29.805 63.2537 31.8525 60.5819 31.7626C57.8855 31.6743 55.6894 29.4747 55.778 26.9578C55.8764 24.2201 58.0233 22.1972 60.7853 22.2331C63.3537 22.2675 65.4498 24.4524 65.4006 27.0477V27.0526ZM62.2729 26.9578C62.2483 26.1221 61.4742 25.4254 60.5868 25.4303C59.6995 25.4303 58.8516 26.2807 58.9057 27.1115C58.9549 27.8981 59.8029 28.6684 60.6114 28.6635C61.4692 28.6586 62.2975 27.8033 62.2713 26.9578H62.2729Z"
                fill="#0d0d0d"/>
            <path
                d="M29.5055 21.6346C32.0084 21.6346 34.175 23.8391 34.2193 26.4246C34.2635 29.0004 32.1527 31.1199 29.5203 31.1542C26.8042 31.1885 24.6475 29.123 24.6475 26.4884C24.6475 23.8538 26.7796 21.6395 29.5055 21.6346ZM29.4465 24.8776C28.5149 24.8678 27.8556 25.5252 27.8654 26.4492C27.8752 27.2947 28.6445 28.0993 29.4366 28.0944C30.2895 28.0895 31.0817 27.3339 31.0915 26.5326C31.1014 25.5791 30.4174 24.8874 29.4465 24.8825V24.8776Z"
                fill="#0d0d0d"/>
            <path
                d="M24.697 35.1527C26.6307 35.1527 28.3102 36.8585 28.3447 38.8602C28.3791 40.8521 26.7439 42.4924 24.7069 42.5185C22.6059 42.5431 20.9346 40.9469 20.9346 38.9109C20.9346 36.8748 22.5845 35.1593 24.697 35.1544V35.1527ZM24.6527 37.6647C23.9294 37.6598 23.4226 38.1635 23.4276 38.8814C23.4325 39.534 24.0279 40.1619 24.6413 40.157C25.3006 40.1521 25.9156 39.5683 25.9255 38.9468C25.9353 38.206 25.4056 37.6762 24.6511 37.6663L24.6527 37.6647Z"
                fill="#0d0d0d"/>
            <path
                d="M53.9046 39.5127C53.9144 40.4514 53.0419 41.3312 52.0808 41.3509C51.1098 41.3705 50.152 40.4612 50.1126 39.4783C50.0733 38.52 50.936 37.6156 51.8971 37.6156C53.0517 37.6156 53.8948 38.4072 53.9046 39.5143V39.5127Z"
                fill="#0d0d0d"/>
            <path
                d="M49.2991 43.9953C49.3188 45.8989 47.5491 47.6782 45.6056 47.7175C43.6423 47.7567 41.7037 45.9136 41.6201 43.9217C41.5364 41.9838 43.2914 40.1554 45.2333 40.1505C47.5737 40.1505 49.2729 41.7565 49.2975 43.9953H49.2991Z"
                fill="#0d0d0d"/>
            <path
                d="M30.5178 33.6008C31.5134 33.5959 32.4007 34.5002 32.3859 35.5142C32.3712 36.4725 31.533 37.3278 30.5719 37.3671C29.5813 37.4063 28.6579 36.5265 28.6333 35.5142C28.6087 34.5411 29.5255 33.6106 30.5227 33.6057L30.5178 33.6008Z"
                fill="#0d0d0d"/>
            <path d="M40.4645 31.5204L31.2617 47.414L32.8795 48.3454L42.0823 32.4517L40.4645 31.5204Z" fill="#0d0d0d"/>
            <path d="M46.3994 35.0399H44.5312V42.9094H46.3994V35.0399Z" fill="#0d0d0d"/>
            <path d="M57.1601 26.0485H49.7549V28.3364H57.1601V26.0485Z" fill="#0d0d0d"/>
            <path d="M25.5656 26.338L18.5547 28.7151L19.268 30.8068L26.279 28.4297L25.5656 26.338Z" fill="#0d0d0d"/>
            <path d="M37.4133 11.3598L35.6143 12.302L41.4943 23.4648L43.2934 22.5226L37.4133 11.3598Z" fill="#0d0d0d"/>
        </g>
        <defs fill="#ffffff">
            <clipPath id="clip0_175_4081" fill="#ffffff">
                <rect width="89" height="78" fill="#0d0d0d"/>
            </clipPath>
        </defs>
    </svg>,
    'Plus',
);

export default CompanyIcon;