export const mainNavbarItems = [
    {
        id: 1,
        icon: '',
        label: 'Campaign viewer',
        route: 'viewer'
    },
    {
        id: 2,
        icon: '',
        label: 'Graph RAG',
        route: 'graphrag'
    }
]