/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onGraphRagChatResponse = /* GraphQL */ `
  subscription OnGraphRagChatResponse($id: String!) {
    onGraphRagChatResponse(id: $id) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
